import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["futureArrivalDate", "fadGroup", "fadLoading"];

  update(event) {
    const frameId = event.target.closest('turbo-frame').id;
    const lineItemId = frameId.match(/\d+/)[0];
    const url = `/store/checkout/multi_arrival_dates/${lineItemId}`;

    const params = {
      'future_arrival_date': this.futureArrivalDateTarget.value
    };
    const queryString = new URLSearchParams(params).toString();
    const urlWithParams = `${url}?${queryString}`;

    this.fadLoadingTarget.classList.remove('d-none');
    this.fadGroupTarget.classList.add('opacity-50');

    fetch(urlWithParams, {
      headers: {
        "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
      }
    }).then((response) => response.text())
      .then((html) => {
        document.getElementById(frameId).innerHTML = html;
      });
  }
}
