import { Controller } from "@hotwired/stimulus";
import * as bootstrap from 'bootstrap';

export default class extends Controller {
  static classes = ['warning', 'error', 'success', 'info'];

  static targets = ['container', 'toast', 'message'];

  static values = {
    message: String,
    type: String,
    delay: { type: Number, default: 5000 },
  };

  CLASSES_BY_TYPE = {
    warning: this.warningClass,
    error: this.errorClass,
    success: this.successClass,
    info: this.infoClass,
  };

  connect() {
    // If a rails flash message was rendered, display it
    if (this.hasMessageValue) {
      this.notice({ detail: { message: this.messageValue, type: this.typeValue } });
    }
  }

  createToast() {
    // TODO: REVISIT how this works bc it appears to be causing duplicate toasts
    /*
    // Create a new element for the notice
    const newToast = this.toastTarget.cloneNode(true);
    this.containerTarget.appendChild(newToast);

    // Remove from the DOM on hide
    newToast.on('hidden.bs.toast', (e) => {
      e.target.remove();
    });

    return new bootstrap.Toast(newToast);
    */
    return new bootstrap.Toast(this.toastTarget, { delay: this.delayValue });
  }

  notice(event) {
    const { detail: { message, type } } = event;

    const activeClass = this.CLASSES_BY_TYPE[type];

    // Remove any current type classses
    Object.values(this.CLASSES_BY_TYPE).forEach((cssClass) => this.toastTarget.classList.remove(cssClass));

    this.toastTarget.classList.add(activeClass);
    this.messageTarget.textContent = message;

    this.createToast().show();
  }
}
