import TemplateHelper from "./template_helper";
import FontHelper from "./font_helper";

const MIME_TYPE = 'image/svg+xml';

export default class SVG {
  constructor(template) {
    this.template = SVG.normalizeTemplate(template);
  }

  render(data, fonts, options) {
    const svg = this.template.cloneNode(true);
    return SVG.render(svg, data, fonts, options);
  }

  toImage() {
    return new Promise(
      (resolve, reject) => {
        const image = new Image();

        image.onload = () => {
          URL.revokeObjectURL(image.src);
          resolve(image);
        };

        image.onerror = () => {
          URL.revokeObjectURL(image.src);
          reject();
        };

        const src = SVG.serializeSvg(this.template);
        const blob = new Blob([src], { type: MIME_TYPE });
        image.src = URL.createObjectURL(blob);
      }
    );
  }

  static async render(template, data, fonts, options) {
    const { width, height, fill } = options || {};

    // Apply any data to the template which will return a new SVG
    let svg = this.apply(template, data);

    if (width >= 0) {
      svg.setAttribute('width', width);
    }

    if (height >= 0) {
      svg.setAttribute('height', height);
    }

    if (fill) {
      svg.style.fill = fill;
    }

    svg = await FontHelper.injectFonts(svg, fonts)
      .then((copy) => FontHelper.resize(copy, fonts));

    return new SVG(svg);
  }

  static normalizeTemplate(template) {
    if (typeof template === 'string') {
      const parser = new DOMParser();
      template = parser.parseFromString(template, MIME_TYPE).querySelector('svg');
    }

    return TemplateHelper.makeIdsUnique(template);
  }

  static apply(template, data) {
    const svg = template.cloneNode(true);

    svg.querySelectorAll('[data-input-name]').forEach((el) => {
      const { inputName, textTemplate, textTransform } = el.dataset;
      let inputValue = (data ? data[inputName] : el.textContent) || '';

      if (textTemplate) {
        inputValue = textTemplate.replace(/{{text}}/g, inputValue);
      }

      switch (textTransform) {
        case 'uppercase':
          inputValue = inputValue.toUpperCase();
          break;
        case 'lowercase':
          inputValue = inputValue.toLowerCase();
          break;
        default:
          // NO-OP
          break;
      }

      el.textContent = inputValue.trim();
    });

    return svg;
  }

  static serializeSvg(svg) {
    return new XMLSerializer().serializeToString(svg);
  }

  static async fetch(url) {
    return fetch(url)
      .then((response) => response.text())
      .then((template) => new SVG(template));
  }

}
