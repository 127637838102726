export class FontMetrics {
  /*
   * Creates a new FontMetrics object to support text size calculations for the given font.
   *
   */
  constructor(fontData) {
    this.glyphWidths = fontData.glyphWidths;
    this.ascent = fontData.ascent;
    this.descent = fontData.descent;
  }

  /*
   * Calculate how much to resize a font so that 'text' fits into 'maxWidth' pixels in a single
   * line. First calculates the text width at the given size, then adjusts the font size
   * downwards so that the text will fit.
   *
   * @param {String} text to resize
   * @param {Number} maxWidth maximum width in pixels for the text
   * @param {Number} fontSize base font size in pixels
   * @returns {Number} the resize ratio
   */
  resizeRatio(text, maxWidth, fontSize) {
    let width = this.textWidth(text, fontSize);

    if (width > maxWidth) {
      return maxWidth / width;
    } else {
      return 1;
    }
  }

  /*
   * Adjust the <text> element font size and baseline. If the text width is larger than the max
   * allowed width, scale the font down. If the font is scaled down, adjust the baseline by
   * modifying the y position on the text element.
   */
  static adjustFontSizeAndBaseline(element, fonts) {
    let maxWidth = parseFloat(element.getAttribute('data-max-width'));
    let baseSize = parseFloat(element.getAttribute('font-size'));

    let font = fonts[element.getAttribute('font-family')];

    if (!font) {
      return;
    }

    let fontMetrics = new FontMetrics(font);

    let text = element.textContent.trim();
    let size = baseSize * fontMetrics.resizeRatio(text, maxWidth, baseSize);

    element.setAttribute('font-size', size + 'px');

    let dy = 0;

    if (fontMetrics.ascent > 0) {
      let baselineRatio = Math.abs(fontMetrics.descent / fontMetrics.ascent);

      dy = (size - baseSize) * baselineRatio;
    }

    if (element.nodeName === 'textPath') {
      // The dy attribute does not work for textPath elements; set it on the parent text element
      // instead!
      element.parentNode.setAttribute('dy', dy);
    } else {
      element.setAttribute('dy', dy);
    }
  }

  /**
   * Calculate the width of the text string rendered with the given font at the given size.
   *
   * @param {String} text text to measure
   * @param {String} fontSize font size in pixels
   * @returns {Number} rendered width of text string in pixels
   */
  textWidth(text, fontSize) {
    let widthInFontUnits = 0;

    for (let i = 0; i < text.length; i += 1) {
      widthInFontUnits += (this.glyphWidths[text.charAt(i)] || this.glyphWidths.max);
    }

    return widthInFontUnits * fontSize;
  }
}
