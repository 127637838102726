const _updateIdReferences = (svg, id, replacementId) => {
  svg.querySelectorAll('*').forEach((el) => {
    if (el.getAttribute('href') === `#${id}`) {
      el.setAttribute('href', `#${replacementId}`);
    }

    if (el.getAttribute('xlink:href') === `#${id}`) {
      el.setAttribute('xlink:href', `#${replacementId}`);
    }

    Object.values(el.attributes).forEach((attr) => {
      const value = attr.value;

      if (value === `url(#${id})` || value === `url("#${id}")`) {
        attr.value = `url(#${replacementId})`;
      }
    });
  });
};

const makeIdsUnique = (svg) => {
  const prefix = `template-${crypto.randomUUID()}`;

  svg.querySelectorAll('[id]').forEach((el) => {
    const id = el.getAttribute('id');
    const replacementId = `${prefix}-${id}`;

    el.setAttribute('id', replacementId);
    _updateIdReferences(svg, id, replacementId);
  });

  return svg;
};

export default {
  makeIdsUnique: makeIdsUnique,
};
