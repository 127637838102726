import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "paymentElement",
    "paymentErrors",
    "paymentToken",
    "stripeScript",
    "submit",
    "waitingIndicator",
  ];

  static values = {
    email: String, billingAddress: Object, key: String, clientSecret: String, apiVersion: String, returnUrl: String
  };

  connect() {
    this.waitingIndicatorTarget.hidden = true;
    this.submitTarget.disabled = true;
    const controller = this;
    this.stripeScriptTarget.addEventListener(
      "load",
      function () {
        const paymentElement = controller.elements.create("payment", controller.paymentOptions);
        paymentElement.mount(controller.paymentElementTarget);
        paymentElement.addEventListener("change", function (event) { controller.change(event); });
      }
    );
  }

  change(event) {
    if (event.error) {
      this.paymentErrorsTarget.textContent = event.error.message;
      this.paymentErrorsTarget.classList.add('d-block');
    } else {
      this.paymentErrorsTarget.textContent = "";
      this.paymentErrorsTarget.classList.remove('d-block');
    }

    this.submitTarget.disabled = !event.complete;
  }

  submit(event) {
    event.preventDefault();

    // NOTE: stripe.confirmPayment may take several seconds to complete.
    // During this time, we need to disable our form from being resubmitted and show a waiting indicator.
    // If we receive an error result, we show that error to the customer, re-enable the form,
    // and hide the waiting indicator.
    this.submitTarget.disabled = true;
    this.submitTarget.hidden = true;
    this.waitingIndicatorTarget.hidden = false;

    const controller = this;
    const options = { elements: this.elements, redirect: "if_required", confirmParams: this.confirmParams };
    this.stripe.confirmPayment(options).then(function (result) {
      if (result.error) {
        // This point will only be reached if there is an immediate error when confirming the payment.
        controller.paymentErrorsTarget.textContent = result.error.message;
        controller.paymentErrorsTarget.classList.add('d-block');
        controller.submitTarget.disabled = false;
        controller.submitTarget.hidden = false;
        controller.waitingIndicatorTarget.hidden = true;
      } else {
        // Not hit for "redirect-based" payment methods as the customer will be redirected to `return_url`
        // before Promise ever resolves.
        // For some payment methods like iDEAL, your customer will be redirected to an intermediate site
        // first to authorize the payment, then redirected to the `return_url`.
        // For "non-redirect-based" payment methods we will handle the form submission
        controller.paymentTokenTarget.value = "stripe_payment_intent";
        event.target.submit();
      }
    });
  }

  get stripe() {
    if (this._stripe === undefined) {
      this._stripe = Stripe(this.keyValue, {apiVersion: this.apiVersionValue}); // eslint-disable-line no-undef
    }
    return this._stripe;
  }

  get elements() {
    if (this._elements === undefined) {
      this._elements = this.stripe.elements(this.elementOptions); // eslint-disable-line no-undef
    }
    return this._elements;
  }

  get elementOptions() {
    return {
      clientSecret: this.clientSecretValue,
    };
  }

  get paymentOptions() {
    return {
      fields: {
        billingDetails: 'never',
      },
    };
  }

  get confirmParams() {
    return {
      payment_method_data: {
        billing_details: this.billingDetails,
      },
      return_url: this.returnUrlValue,
    };
  }

  get billingDetails() {
    return {
      'name': `${this.billingAddressValue.first_name || ''} ${this.billingAddressValue.last_name || ''}`.trim(),
      'email': this.emailValue || '',
      'phone': this.billingAddressValue.phone || '',
      'address': {
        'line1': this.billingAddressValue.street_1 || '',
        'line2': this.billingAddressValue.street_2 || '',
        'city': this.billingAddressValue.city || '',
        'state': this.billingAddressValue.state || '',
        'postal_code': this.billingAddressValue.zip || '',
        'country': this.billingAddressValue.country || '',
      },
    };
  }
}
