import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = [
    "personalization2--preview",
  ];

  static values = {
    activeDesign: String,
  };

  static targets = ["activeDesign"];

  connect() {
    if (this.hasActiveDesignValue) {
      this.assignedDesignName = this.activeDesignValue;
      this._setActiveDesign();
    }
  }

  change(event) {
    this.activeDesignValue = event.params.designName;
    this._setActiveDesign();
    this.personalization2PreviewOutlets.forEach((outlet) => {
      outlet.changeTemplate(event.params.template);
    });
  }

  _setActiveDesign() {
    if (this.hasActiveDesignTarget) {
      this.activeDesignTarget.innerHTML = this.activeDesignValue;
    }
  }
}
