import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static outlets = ["personalization2--template"];

  update(payload) {
    this.personalization2TemplateOutlets.forEach((outlet) => {
      outlet.dataValue = payload;
      outlet.update();
    });
  }

  changeTemplate(template) {
    this.personalization2TemplateOutlets.forEach((outlet) => {
      outlet.urlValue = template.url;
      outlet.invertValue = template.invert;
      outlet.fontsValue = template.fonts;

      outlet.fetchAndUpdate();
    });
  }
}
