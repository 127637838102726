import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["count"];
  }

  static get values() {
    return {
      count: { type: Number, default: 1 },
      minimum: { type: Number, default: 1 },
      maximum: { type: Number, default: 25 },
    };
  }

  connect() {
    this.renderCount();
  }

  decrement() {
    this.countValue -= 1;
    this.renderCount();
  }

  increment() {
    this.countValue += 1;
    this.renderCount();
  }

  renderCount() {
    this.countValue = Math.min(Math.max(this.countValue, this.minimumValue), this.maximumValue);
    if (this.countTarget.tagName === "INPUT") {
      this.countTarget.value = this.countValue;
    } else {
      this.countTarget.innerHTML = this.countValue;
    }
  }
}
