import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "form",
    "email",
    "firstName",
    "lastName",
    "phone",
    "country",
    "shippingFullName",
    "shippingStreet1",
    "shippingStreet2",
    "shippingCity",
    "shippingState",
    "shippingZip",
    "shippingCountry",
    "paymentToken",
    "deviceData",
    "buttons",
    "braintreeScript",
  ];

  static values = {
    key: String,
    currency: String,
    amount: Number,
  };

  connect() {
    const controller = this;
    this.braintreeScriptTarget.addEventListener(
      "load",
      function () {
        braintree.client.create({ authorization: controller.keyValue }) // eslint-disable-line no-undef
          .then(function (client) {
            controller._initButton(client);
            controller._initDataCollection(client);
          })
          .catch(function (error) {
            console.error("Error initializing PayPal:", error); // eslint-disable-line no-console
            controller.hide();
          });
      }
    );
  }

  hide() {
    this.buttonsTarget.hidden = true;
  }

  show() {
    this.buttonsTarget.hidden = false;
  }

  _initDataCollection(client) {
    const controller = this;
    return braintree.dataCollector.create({ client: client }) // eslint-disable-line no-undef
      .then(function (dataCollector) {
        controller.deviceDataTarget.value = dataCollector.deviceData;
      });
  }

  _initButton(client) {
    const controller = this;
    return braintree.paypalCheckout.create({ client: client }) // eslint-disable-line no-undef
      .then(function (checkout) {
        return checkout.loadPayPalSDK({ intent: "authorize", currency: controller.currencyValue });
      }).then(function (checkout) {
        return paypal.Buttons({ // eslint-disable-line no-undef
          fundingSource: paypal.FUNDING.PAYPAL, // eslint-disable-line no-undef
          style: { color: 'black', height: 45 },
          createOrder: function () {
            return checkout.createPayment({
              flow: "checkout",
              intent: "authorize",
              amount: controller.amountValue,
              currency: controller.currencyValue,
              enableShippingAddress: true,
            });
          },
          onApprove: function (data, _actions) { // eslint-disable-line no-unused-vars
            return checkout.tokenizePayment(data).then(function (payload) {
              controller._payPalTokenize(payload);
            });
          },
          onError: function (error) {
            if (error.type !== 'CUSTOMER') { controller._payPalError("Unrecoverable error from PayPal", error); }
          }
        }).render(controller.buttonsTarget);
      });
  }

  _payPalTokenize(payload) {
    const address = payload.details.shippingAddress;
    if (address) {
      this.shippingFullNameTarget.value = address.recipientName || '';
      this.shippingStreet1Target.value = address.line1 || '';
      this.shippingStreet2Target.value = address.line2 || '';
      this.shippingCityTarget.value = address.city || '';
      this.shippingStateTarget.value = address.state || '';
      this.shippingZipTarget.value = address.postalCode || '';
      this.shippingCountryTarget.value = address.countryCode || '';
    }

    if (this.hasEmailTarget) {
      this.emailTarget.value = payload.details.email || '';
    }
    this.firstNameTarget.value = payload.details.firstName || '';
    this.lastNameTarget.value = payload.details.lastName || '';
    this.phoneTarget.value = payload.details.phone || '';
    this.countryTarget.value = payload.details.countryCode || '';

    this.paymentTokenTarget.value = payload.nonce;
    this.formTarget.submit();
  }
}
