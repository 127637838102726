import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cardElement", "cardErrors", "paymentToken", "submit", "stripeScript"];

  static values = { billingAddress: Object, key: String, apiVersion: String };

  connect() {
    // Admin form has optional payment, so if no card element, then nothing to do
    if (!this.hasCardElementTarget) {
      return;
    }

    this.submitTarget.disabled = true;
    const controller = this;
    this.stripeScriptTarget.addEventListener(
      "load",
      function () {
        controller.card.addEventListener("change", function (event) { controller.change(event); });
      }
    );
  }

  change(event) {
    if (event.error) {
      this.cardErrorsTarget.textContent = event.error.message;
      this.cardErrorsTarget.classList.add('d-block');
    } else {
      this.cardErrorsTarget.textContent = "";
      this.cardErrorsTarget.classList.remove('d-block');
    }

    if (event.complete) {
      this.submitTarget.disabled = false;
    } else {
      this.submitTarget.disabled = true;
    }
  }

  submit(event) {
    // Admin form has optional payment, so if no card element, then nothing to do
    if (!this.hasCardElementTarget) {
      event.target.submit();
      return;
    }

    event.preventDefault();
    const controller = this;
    this.submitTarget.disabled = true;
    this.stripe.createToken(this.card, this.cardData).then(function (result) {
      if (result.error) {
        controller.cardErrorsTarget.textContent = result.error.message;
        controller.cardErrorsTarget.classList.add('d-block');
        controller.submitTarget.disabled = false;
      } else {
        controller.paymentTokenTarget.value = result.token.id;
        event.target.submit();
      }
    });
  }

  get stripe() {
    if (this._stripe === undefined) {
      this._stripe = Stripe(this.keyValue, {apiVersion: this.apiVersionValue}); // eslint-disable-line no-undef
    }
    return this._stripe;
  }

  get card() {
    if (this._card === undefined) {
      this._card = this.stripe.elements().create("card", this.cardOptions);
      this._card.mount(this.cardElementTarget);
    }
    return this._card;
  }

  get cardData() {
    // Billing zip code must be submitted with card info for verification of CC.
    // The rest of this information gets sent to our Stripe dashboard, but is currently non-essential.
    return {
      'name': `${this.billingAddressValue.first_name || ''} ${this.billingAddressValue.last_name || ''}`.trim(),
      'address_line1': this.billingAddressValue.street_1 || '',
      'address_line2': this.billingAddressValue.street_2 || '',
      'address_city': this.billingAddressValue.city || '',
      'address_state': this.billingAddressValue.state || '',
      'address_zip': this.billingAddressValue.zip || '',
      'address_country': this.billingAddressValue.country || '',
    };
  }

  get cardOptions() {
    return {
      style: { base: { lineHeight: "40px" } },
      hidePostalCode: true,
      classes: {
        invalid: 'field-with-errors',
        focus: 'field-focused'
      }
    };
  }
}
