import { Controller } from '@hotwired/stimulus';
import { Modal } from 'bootstrap';

export default class extends Controller {
  static values = {
    autoShow: { type: Boolean, default: false },
  };

  connect() {
    this.modal = new Modal(this.element);

    if (this.autoShowValue) {
      this.modal.show();
    }
  }

  close() {
    this.modal.hide();
  }

  closeOnSuccess(evt) {
    if (evt.detail.success) {
      this.close();
    }
  }

  cleanup() {
    this.element.remove();
  }
}
