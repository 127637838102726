import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select"];

  static values = { choices: Object, emptyChoice: String };

  change(event) {
    this.selectTarget.innerHTML = this._choicesFor(event.target.value);
  }

  _choicesFor(value) {
    let choices = [];
    if (this.hasEmptyChoiceValue) {
      choices.push(`<option>${this.emptyChoiceValue}</option>`);
    }

    (this.choicesValue[value] || []).forEach((options) => {
      choices.push(`<option value="${options[1]}">${options[0]}</option>`);
    });

    return choices.join('');
  }
}
