import { FontMetrics } from "../font_metrics";

/**
 * Creates a CSS font face definition for a font. When rendered in a <canvas>, the fonts must use
 * inline data URIs for loading. Thus, the font is first loaded via an AJAX request, and then
 * converted to a font face defintition that uses a data URI `src`.
 *
 * As of 2020-20-07, the folowing alternative options do not work for rendering:
 * - blob URLs (from URL.createObjectURL)
 * - remote (e.g.: 'https://cdn.mancrates.com/font.ttf')
 * - FontFace API
 *
 * The font _must_ be loaded via a data URI; otherwise, the font is missing from the rendered
 * print job image. Any of these options would be better options for font loading if they work one
 * day, since data URIs have size limits and can be slow.
 */
const renderFontFace = async (font) => {
  const blobToDataUrl = (blob) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });

  const response = await fetch(font.url);
  const fontData = await response.blob();
  const fontDataUrl = await blobToDataUrl(fontData);
  const { name, format } = font;

  return `
    @font-face {
      font-family: '${name}';
      src: url('${fontDataUrl}') format('${format}');
    }
  `;
};

/**
 * Creates a <style> tag containing font definitions for the job's custom fonts. Since jobs are
 * rendered in a <canvas>, the fonts must use inline data URIs for loading. Thus, the fonts are
 * first loaded via an AJAX request, and then converted to data URIs before embedding in the
 * <style> tag.
 */
const renderFontStyleTag = async (fonts) => {
  const css = await Promise.all(Object.values(fonts).map(renderFontFace));
  const defs = document.createElement("defs");
  const style = document.createElement("style");
  style.appendChild(document.createTextNode(css.join("")));
  defs.appendChild(style);

  return defs;
};

// Create a copy of the SVG and inject our fonts into it
const injectFonts = async (svg, fonts) => {
  if (!fonts) { return svg; }

  const working = svg.cloneNode(true);
  const style = await renderFontStyleTag(fonts);
  working.insertBefore(style, working.firstChild);

  return working;
};

const calculateResize = (el, fonts) => {
  const maxWidth = parseFloat(el.dataset.maxWidth);
  const baseSize = parseFloat(el.getAttribute("font-size"));
  const font = fonts[el.getAttribute("font-family")];

  if (!font) {
    return;
  }

  const fontMetrics = new FontMetrics(font);
  const text = el.textContent.trim();
  const size = baseSize * fontMetrics.resizeRatio(text, maxWidth, baseSize);

  el.setAttribute("font-size", `${size}px`);

  let dy = 0;

  if (fontMetrics.ascent > 0) {
    const baselineRation = Math.abs(fontMetrics.descent / fontMetrics.ascent);

    dy = (size - baseSize) * baselineRation;
  }

  if (el.nodeName === "textPath") {
    // The dy attribute does not work for textPath elements; set it on the parent text element instead
    el.parentNode.setAttribute("dy", dy);
  } else {
    el.setAttribute("dy", dy);
  }
};

// Find all <text> elements that require automatic font resizing and calculate the ideal
// font size for each
const resize = (svg, fonts) => {
  const workingCopy = svg.cloneNode(true);
  workingCopy.querySelectorAll("[data-font-resize='yes']").forEach((el) => {
    calculateResize(el, fonts || {});
  });

  return workingCopy;
};

export default {
  resize: resize,
  injectFonts: injectFonts,
};
