import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  static outlets = ["personalization2--preview"];

  connect() {
    this._configureInputs();
    this.update();
  }

  update() {
    this.personalization2PreviewOutlets.forEach((outlet) => outlet.update(this.payload));
  }

  _fetchValue(input) {
    let value = input.value;
    if (value.length === 0) {
      return null;
    }

    const { maxLength } = input.dataset;
    if (maxLength) {
      value = value.substring(0, maxLength);
    }

    return value;
  }

  // Update all of our inputs with maxLength attributes if applicable
  // TODO: Can this move to the form helpers file?
  _configureInputs() {
    this.inputTargets.forEach((input) => {
      const { maxLength } = input.dataset;

      if (maxLength) {
        input.setAttribute("maxlength", input.dataset.maxLength);
      }
    });
  }

  get payload() {
    const _payload = {};
    this.inputTargets.forEach((input) => { _payload[input.dataset.name] = this._fetchValue(input); });
    return _payload;
  }
}
