import { Autocomplete } from 'stimulus-autocomplete';

export default class QuickSearch extends Autocomplete {
  static targets = ["form", "modal"];

  connect() {
    this.searchText = '';

    this.element.addEventListener("autocomplete.change", this.onSelect.bind(this));
    this.element.addEventListener("loadstart", this.loadstart.bind(this));
    this.formTarget.addEventListener("submit", this.onSubmit.bind(this));
    this.modalTarget.addEventListener("shown.bs.modal", this.onOpen.bind(this));

    super.connect();
  }

  disconnect() {
    this.element.removeEventListener("autocomplete.change", this.onSelect.bind(this));
    this.element.removeEventListener("loadstart", this.loadstart.bind(this));
    this.formTarget.removeEventListener("submit", this.onSubmit.bind(this));
    this.modalTarget.removeEventListener("shown.bs.modal", this.onOpen.bind(this));

    super.disconnect();
  }

  onOpen() {
    this.inputTarget.focus();
  }

  onSubmit(event) {
    // Ignore search form submit when no query present
    if (this.inputTarget.value === "") {
      event.preventDefault();
    }
  }

  loadstart() {
    this.searchText = this.inputTarget.value;
  }

  onSelect(event) {
    this.inputTarget.value = this.searchText;

    // Navigate to selected item retaining the query parameter for consumption by analytics
    window.location = event.detail.value + '?q=' + this.searchText;
  }
}
